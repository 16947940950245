// This file is autogenerated. DO NOT EDIT.
//
// Generated by public/app/plugins/gen.go
//
// Derived from the Thema lineage declared in models.cue
//
// Run `make gen-cue` from repository root to regenerate.



export const PanelModelVersion = Object.freeze([0, 0]);

export interface PanelOptions {
  /**
   * empty/missing will default to grafana blog
   */
  feedUrl?: string;
  showImage?: boolean;
}

export const defaultPanelOptions: Partial<PanelOptions> = {
  showImage: true,
};
